import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Skills",
        tab2 = "Experience",
        tab3 = "Timeline";
        const { tabStyle } = this.props
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a href="/service">Design<span></span></a>
                                                    Draw pictures, pair colors, make things pretty, create original art.
                                                </li>
                                                <li>
                                                    <a href="/service">Tools</a>
                                                    Slack, Jira, GSuite, Office, Zoom, ChatGPT, Photoshop, Illustrator, Gimp <br/>
                                                    React, Javascript, Some AWS, VS Code, GitHub, HTML/CSS, Solidity, Web3, Klaviyo, Magento, Analytics, Search Console. 
                                                </li>
                                                <li>
                                                    <a href="/service">Marketing - Branding<span> </span></a>
                                                    Developing and reaching audiences, narative building, value lever creation, social media, understanding customers.
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">6Sole <span>- Sneaker Marketplace</span></a> 2019 - 2020
                                               </li>
                                               <li>
                                                   <a href="/service">Popes Closet <span>- Sneaker Reseller</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Web3 Projects <span>- Still Exploring</span></a> 2013- 2014
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>


                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">Vail Mountain School<span> - Vail, Colorado</span></a> 2018 - Current
                                               </li>
                                               <li>
                                                   <a href="/service">Northeastern University<span> - Boston, MA</span></a> 2017 - 2018
                                               </li>
                                               <li>
                                                   <a href="/service">Isreal Study Abroad<span> - 2013- 2014 </span></a> 
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <ul>
                                               <li>
                                                   <a href="/service">BSc In CSE<span> - ABC University, Los Angeles, CA</span></a> 2010
                                               </li>
                                               <li>
                                                   <a href="/service">Diploma in Computer Science<span> - Gamma Technical Institute</span></a> 2009
                                               </li>
                                               <li>
                                                   <a href="/service">Graphic Designer<span> - ABC Institute, Los Angeles, CA</span></a> 2007
                                               </li>
                                           </ul>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;