import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCast />,
        title: 'Digital Stratagy',
        description: 'We work with customer to identify the best places to allocate resources to change their profit model.',
        link: '/digital-strategy'

    },
    {
        icon: <FiLayers />,
        title: 'Systems Architecture',
        description: 'We\'ll look at your system from 40,000 feet, look at the big picture and design a plan to accomplish your goals.',
        link: '/netsuite-systems-architecture'
    },
    {
        icon: <FiUsers />,
        title: 'Process Automation',
        description: 'Automating repetitive tasks results in lower operational costs and more importantly frees your employees to work on higher value activities.',
        link: '/netsuite-process-automation'
    },
    {
        icon: <FiMonitor />,
        title: 'Performance Optimization',
        description: 'As Netsuite implementations grow, performance becomes a big consideration in terms of costs. We optimize and analyze scripts and identify areas for cost improvement.',
        link: '/netsuite-performance-optimization'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'Contact us today to see if we can help you realize more from your software.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href={val.link}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
