const BlogContent = [
    {
        images: '01',
        title: ' The CFOs tool kit',
        category: 'Management',
        link: '/blog/netsuite-cfo-tool-kit'
    },
    {
        images: '02',
        title: 'Does it Belong in Netsuite? ',
        category: 'Management',
        link: '/blog/extending-netsuite'
    },
    {
        images: '03',
        title: 'Marketing with Netsuite',
        category: 'Marketing',
        link: '/blog/netsuite-marketing-automation'
    },
    {
        images: '04',
        title: 'Integration Strategies ',
        category: 'Integration',
        link: '/blog/netsuite-integration-strategies'
    },
    {
        images: '01',
        title: 'Optimizing Performance ',
        category: 'Management',
        link: '/blog/netsuite-performance-optimization'
    },
    {
        images: '02',
        title: 'Simple Automations',
        category: 'Design',
        link: '/blog/simple-netsuite-automations'
    },
]

export default BlogContent;