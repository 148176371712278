import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}
function ContactForm({props}) {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: '',
        phone: '',
        subject: '',
        message: ''
      });
    
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };  

    const handleSubmit = async (event) => {
        event.preventDefault();
      
        try {
          const response = await fetch('https://5d1k431rw8.execute-api.us-east-1.amazonaws.com/Prod', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
          });
      
          if (!response.ok) {
            throw new Error('Error sending email');
          }
      
          alert('Email sent successfully');
        } catch (error) {
          console.error(error);
          alert('Error sending email');
        }
      };
        
    return (
        <form action="" onSubmit={handleSubmit}>
            <div className="rn-form-group">
                <input 
                type="text"
                name="fullname"
                placeholder="Your Name"
                onChange={handleChange}
                value={formData.fullName}
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="email"
                name="email"
                placeholder="Your Email"
                onChange={handleChange}
                value={formData.email}
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="phone"
                placeholder="Phone Number"
                onChange={handleChange}
                value={formData.phone}
                required
                />
            </div>

            <div className="rn-form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                onChange={handleChange}
                value={formData.subject}
                required
                />
            </div>
            
            <div className="rn-form-group">
                <textarea 
                name="message"
                placeholder="Your Message"
                onChange={handleChange}
                value={formData.message}
                required
                >
                </textarea>
            </div>

            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
            </div> 

            
        </form>
    )
}
export default ContactForm;
