import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Ethan Pope || Creative, Motivated, and Ready to Work</title>
                    <meta name="description" content="A Page about Ethan Pope" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
