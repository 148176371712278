import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Digital Strategy',
        description: 'There are many options to choose from. We measure the success of our implementations by the change in the profit model.'
    },
    {
        icon: '02',
        title: 'Systems Architecture',
        description: 'We hire software to do a job. One of those jobs is Automation. We break down processs into rules and schedules and reduce the human interaction so you can use that resoure elsewhere. '
    },
    {
        icon: '03',
        title: 'Process Automation',
        description: 'Every implementation is customized which is why an off the shelf integration rarely works. We leverage large libraries to quickly build tailored, performant integrations'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;