import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'My First Startup',
        title: 'An Online Sneaker Marketplace',
        link: '/sixsole'
    },
    {
        image: 'image-2',
        category: 'The MVP that just worked',
        title: 'Creating a Brand and Reputation',
        link: '/popescloset'
    },
    {
        image: 'image-3',
        category: 'Its not that easy',
        title: 'Dipping my toes in Web3',
        link: '/luckyzebras'
    },
    {
        image: 'image-4',
        category: 'This Might Work',
        title: 'The value of a plan',
        link: '/ensscapes'
    },
    {
        image: 'image-3',
        category: 'When they tell you maybe',
        title: 'Getting into the college I want',
        link: '/northeastern'
    },
    {
        image: 'image-4',
        category: 'Marketing Me',
        title: 'Learning to Publish, Learning the Cloud',
        link: '/ethanwww'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/portfolio-details">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={value.link}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;